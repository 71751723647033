import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SimpleTableData, SimpleTableComponent, SimpleTableColumn } from '@delon/abc';
import { ModalHelper } from '@delon/theme';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { GuidUtil } from '@shared/utils/guid.util';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsEditComponent } from '../ths-news/edit/edit.component';
import { ThsNewsService } from '../ths-news/ths-news.service';
import { BimLearningClassRoomEditComponent } from './edit/edit.component';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';

@Component({
  selector: 'app-ths-bim-learning-classroom',
  templateUrl: './bim-learning-classroom.component.html',
  styleUrls: []
})
export class BimLearningClassRoomComponent implements OnInit {

  /**
   * contentType
  */
  @Input() contentType = 'LearningClassRoom';

  public page = 0;
  public size = 10;
  public total = '0';
  public pageSizeOptions = [10, 20, 50, 100];
  selectedRows: SimpleTableData[] = [];

  public currentBimInfo = null;
  public bimInfoStatus = -1;

  public bimInfoList = [];

  public isloadding = false;
  public list: any[];
  @ViewChild('st') st: SimpleTableComponent;

  public queryParams = {
    // 查询搜索框的字段要预置
    'content.contains': null,
    'searchValue.contains': null,
    // sort固定
    sort: ['istop,desc', 'hit,asc', 'createTime,desc']
  };

  columns: SimpleTableColumn[] = [
    { title: '排序', index: 'hit', width: '5%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '学习课堂名称', index: 'title', format: (item: any, col: any) => this.formatIeCol(item, col) },
    { title: '链接网址', index: 'remark', width: '40%', format: (item: any, col: any) => this.formatIeCol(item, col) },
    {
      title: '是否显示', index: 'istop', width: '8%',
      format: (item: any) => {
        return item.istop === 1 ? '是' : '否';
      }
    },
    {
      title: '所属赛事', index: 'bimInfoId', width: '26%',
      format: (item: any) => {
        if (isNullOrUndefined(item.bimInfoId)) {
          return '';
        } else {
          const data = this.bimInfoList.filter(e => e.id === item.bimInfoId);
          if (data && data.length > 0) {
            return data[0].name;
          }
          return '';
        }
      }
    },
    {
      title: '操作', width: '10%',
      buttons: [
        { text: '编辑', click: (item: any) => this.edit(item) },
        { text: '删除', click: (item: any) => this.remove(item) }
      ],
    }
  ];

  constructor(
    private modal: ModalHelper,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private fileUploadService: FileUploadService,
    private thsNewsService: ThsNewsService,
    private bimInfoService: BimInfoService,
  ) { }

  ngOnInit(): void {
    console.log(this.contentType);
    this.initBimInfoList();
    this.getDataList();
  }


  initBimInfoList() {
    this.isloadding = true;
    this.bimInfoService.query({
      'size': '10000',
      'status.in': ['0', '1'],
      'isDelete.equals': 0,
      'callSource.equals' : '1',//标识请求来源于后台,根据区域权限进行省赛数据权限过滤
      'sort': ['createTime,desc']
    }).subscribe((res: any) => {
      this.bimInfoList = res.body;
      this.getDataList();
    }, () => this.isloadding = false);
  }
  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.thsNewsService.query(copyParams).subscribe(res => {
      this.isloadding = false;
      this.list = res.body;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    if (!isNullOrUndefined(this.contentType)) {
      copyParams['contenttype.equals'] = this.contentType;
    }
    // if (!isNullOrUndefined(this.currentBimInfo)) {
    //   copyParams['bimInfoId.equals'] = this.currentBimInfo.id;
    // }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['callSource.equals'] = '1';//后台
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
  * 新增页面
  */
  add() {
    const dataId = new GuidUtil().toString();
    this.modal.static(BimLearningClassRoomEditComponent, {
      isAdd: true,
      record: { id: null },
      dataId: dataId,
      contentType: this.contentType
    }).subscribe((res) => {
      console.log(res);
      if (res === 'success') {
        this.getDataList();
      } else {
        this.fileUploadService.deleteAllByFk(dataId).subscribe();
      }
    });
  }

  /**
   * 编辑页面
   */
  edit(item) {
    this.modal.static(BimLearningClassRoomEditComponent, {
      isAdd: false,
      record: item,
      contentType: this.contentType
    }).subscribe((res) => {
      if (res === 'success') {
        this.getDataList();
      }
    });
  }




  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item.id);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(dataId) {
    this.isloadding = true;
    this.thsNewsService.delete(dataId).subscribe(() => {
      this.isloadding = false;
      this.fileUploadService.deleteAllByFk(dataId).subscribe();
      this.msg.remove();
      this.msg.info('删除成功！');
      this.page = 0;
      this.getDataList();
    });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event: { pi: number; ps: number; }) {
    if (event.pi === undefined) {
      return;
    }
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
    this.page = event - 1;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangeSize(event) {
    this.size = event;
    this.getDataList();
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
  }

  /**
   * ie列表显示null格式化
   */
  formatIeCol = (item: any, col: any) => isNullOrUndefined(item[col.indexKey]) ? '' : item[col.indexKey];

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    // this.getDataList();
  }

  searche(){
    this.page = 0;
    this.getDataList();
  }

}
