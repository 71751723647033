import { Component, OnInit } from '@angular/core';
import { BimWorks, BimWorkVm } from "../work-manage/work-manage.model";
import { BimInfoService } from "../bim-info/bim-info.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BimInfoEventsService } from "../bim-info/bim-info-events/bim-info-events.service";
import { ModalHelper } from "@delon/theme";
import { RegionInfoService } from "../region-info/region-service.model";
import { ProvinceService } from "../provinces/provinces.service";
import { WorkManageService } from "../work-manage/work-manage.service";
import { HttpAddressService } from "@shared/session/http-address.service";
import { MessageHelper } from "@shared/helpers/MessageHelper";
import { ShowTeamUsersComponent } from "../work-manage/show-team-users/show-team-users.component";
import { NzMessageService } from 'ng-zorro-antd';
import { APP_PERMISSIONS } from '@shared/app.permissions';

@Component({
  selector: 'app-school-work-manager',
  templateUrl: './school-work-manager.component.html',
  styles: []
})
export class SchoolWorkManagerComponent implements OnInit {

  searchTeamName = '';
  searchSchoolName = '';
  searchSchoolType = '-1';
  searchRegion = '-1'; //大区
  regionList: any = [{ id: '', regionName: '' }];
  regionListAll: any = [];
  searchProvinces = '-1'; //省份
  provincesList: any = [{ id: '', provinceName: '' }];
  provincesListAll: any = [];
  searchBimInfo = '-1'; //赛事
  bimInfoList: any = [{ id: '', name: '' }];
  searchBimInfoEvents = ''; //赛项
  bimInfoEventsList: any = [{ id: '', name: '' }];
  searchWorkTop = -1; //校内是否晋级

  queryParams = {
    provinceIds: null,//默认省份id数据
    regionIds: null //默认区域id数据
  }

  isQueryEvent = false;

  page = 1;
  size = 20;
  total = 0;
  list: BimWorks[] = [];
  loading = false;
  allChecked = false;
  indeterminate = false;

  teamUrl = '';
  exportLoading = false;
  downloadLoading = false;
  public APP_PERMISSIONS = APP_PERMISSIONS;

  constructor(
    private msg: NzMessageService,
    private regionInfoService: RegionInfoService,
    private provinceService: ProvinceService,
    private bimInfoService: BimInfoService,
    private bimInfoEventsService: BimInfoEventsService,
    private workManageService: WorkManageService,
    public modal: ModalHelper,
    private http: HttpClient,
    private httpAddressService: HttpAddressService
  ) {
    this.teamUrl = this.httpAddressService.BimServe + '/bim-works';
  }

  ngOnInit() {
    // this.getAllRegion();
    // this.getProvinces('-1');
    this.getUserProvinces();
    this.getBimInfo();
    // this.getList();
  }

  /**
   * 获取所有大区
   */
  getAllRegion() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'orderNum',
    };
    if(this.searchRegion && this.searchRegion !== '-1'){
      params['id.in'] = this.searchRegion.split(',');
    }
    this.regionInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        const datas = res.body;
        this.regionList = [{ id: '-1', regionName: '全部' }];
        this.regionList.push(...res.body);
        this.regionListAll.push(...this.regionList);
      }
    })
  }

  /**
   * 获取用户地区信息
   */
  getUserProvinces(){
    this.queryParams['provinceIds'] = null;
    this.queryParams['regionIds'] = null;
    this.provincesListAll = [];
    this.provincesList = [];

    this.provinceService.getUserTypeAndProvinces().subscribe((res: any) => {
      const code = res.body.code;
      const datas = res.body.data;
 
      if(code !== '1' && datas){
        //非管理员
        const regions = [];
        const provinces = [];
        datas.forEach(item => {
          if(!regions.includes(item.fkregionId)){
            regions.push(item.fkregionId);
          }
          provinces.push(item.id);
        });

        this.queryParams['provinceIds'] = provinces;
        this.queryParams['regionIds'] = regions;
        
        this.searchRegion = regions.join(',');
      }

   
      this.provincesList = [{ id: '-1', provinceName: '全部' }];
      this.provincesList.push(...datas);
      this.provincesListAll.push(...this.provincesList);
      
      this.getAllRegion();
      this.getList();
    });
  }

  /**
   * 根据大区获取省份
   */
  getProvinces(value) {
    const params = {
      'fkregionId.equals': value,
      page: 0,
      size: 1000,
      sort: 'orderNum'
    };
    console.log('searchRegion:', this.searchRegion);
    console.log('value:', value);
    if (value === '-1') {
      delete params['fkregionId.equals'];
    }
    this.provinceService.query(params).subscribe(res => {
      if (res && res.body) {
        this.provincesList = [{ id: '-1', provinceName: '全部' }];
        this.provincesList.push(...res.body);
      }
    })
  }

  /**
   * 根据大区获取省份
   */
  getProvincesNew(value) {
    this.provincesList = [];
    this.searchProvinces = '-1';
    if(!value || value === '-1'){
      this.provincesList.push(...this.provincesListAll);
    }else{
      const data = this.provincesListAll.filter(w=>w.id === '-1' || w.fkregionId === value);
      this.provincesList.push(...data);
    }
  }

  /**
   * 获取所有大赛
   */
  getBimInfo() {
    const params = {
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
      'callSource.equals' : '1',//标识请求来源于后台,根据区域权限进行省赛数据权限过滤
      'isDelete.equals': 0
    };
    this.bimInfoService.query(params).subscribe(res => {
      if (res && res.body) {
        this.bimInfoList = [{ id: '-1', name: '全部赛事' }];
        this.bimInfoList.push(...res.body);
      }
    })
  }

  /**
   * 根据赛事ID获取赛项
   */
  getBimInfoEvents(value) {
    if (value === '-1') {
      this.searchBimInfoEvents = '';
      this.bimInfoEventsList = [];
      return;
    }
    const params = {
      'bimInfoId.equals': value,
      'isDelete.equals': 0,
      page: 0,
      size: 1000,
      sort: 'createTime,desc',
    };
    this.isQueryEvent = true;
    this.bimInfoEventsService.query(params).subscribe(res => {
      this.isQueryEvent = false;
      if (res && res.body) {
        this.bimInfoEventsList = [{ id: '-1', name: '全部' }];
        this.bimInfoEventsList.push(...res.body);
        this.searchBimInfoEvents = '-1';
      }
    })
  }

  rest() {
    this.searchTeamName = '';
    this.searchSchoolName = '';
    this.searchRegion = '-1';
    this.searchProvinces = '-1';
    this.searchBimInfo = '-1';
    this.searchBimInfoEvents = '';
    this.searchWorkTop = -1;
    // this.getProvinces('-1');
    this.bimInfoEventsList = [];
    this.page = 1;
    this.getUserProvinces();
    // this.getList();
  }

  getList(pageRest?: boolean) {
    if (pageRest) {
      this.page = 1;
    }
    const params = {
      page: this.page - 1,
      size: this.size,
      'workType.equals': 0,
      sort: ['itemId', 'core,desc'],
    };
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName;
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName;
    }
    if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      params['schoolType.equals'] = this.searchSchoolType;
    }
    if (this.searchRegion && this.searchRegion !== '-1') {
      params['location.equals'] = this.searchRegion;
    }
    if (this.searchProvinces && this.searchProvinces !== '-1') {
      params['province.equals'] = this.searchProvinces;
    }
    if (this.searchBimInfo && this.searchBimInfo !== '-1') {
      if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
        params['eventsId.equals'] = this.searchBimInfoEvents;
      } else {
        params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
      }
    }
    if (this.searchWorkTop !== -1) {
      params['workTop.equals'] = this.searchWorkTop;
    }
    
    if(this.queryParams['provinceIds'] && this.queryParams['provinceIds'].length > 0){
      
    }

    if (this.isQueryEvent) {
      this.msg.warning('请等待参赛项目查询完成');
      return;
    }
    this.loading = true;
    this.workManageService.getBimWork(params).subscribe(res => {
      this.loading = false;
      if (res && res.body) {
        this.list = res.body;
        this.total = parseInt(res.headers.get('X-Total-Count'));
        this.list.forEach(item => {
          item.checked = false;
        })
      }
    }, error1 => {
      this.loading = false;
      console.error(error1.message);
      MessageHelper.error('获取数据错误', error1.status);
    })
  }

  refreshStatus(): void {
    const allChecked = this.list.every(value => value.checked === true);
    const allUnChecked = this.list.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.list.forEach(data => data.checked = value);
    this.refreshStatus();
  }

  viewTeamUsers(data: BimWorks) {
    console.log('data:', data);
    this.modal.static(ShowTeamUsersComponent, { teamId: data.teamId }, 800, {}).subscribe(res => { })
  }

  teamWorkExportExcel() {
    const params = {
      'workType.equals': '0',
      sort: ['itemId', 'core,desc'],
    };
    if (this.searchTeamName && this.searchTeamName.length > 0) {
      params['teamName.contains'] = this.searchTeamName;
    }
    if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      params['schoolName.contains'] = this.searchSchoolName;
    }
    if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      params['schoolType.equals'] = this.searchSchoolType;
    }
    if (this.searchRegion && this.searchRegion !== '-1') {
      params['location.equals'] = this.searchRegion;
    }
    if (this.searchProvinces && this.searchProvinces !== '-1') {
      params['province.equals'] = this.searchProvinces;
    }
    if (this.searchBimInfo && this.searchBimInfo !== '-1') {
      if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
        params['eventsId.equals'] = this.searchBimInfoEvents;
      } else {
        params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
      }
    }
    if (this.searchWorkTop !== -1) {
      params['workTop.equals'] = this.searchWorkTop;
    }
    if (this.isQueryEvent) {
      this.msg.warning('请等待参赛项目查询完成');
      return;
    }
    this.exportLoading = true;
    const url = `${this.teamUrl}/workExportExcel`;
    //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
    this.http.get(url, {
      params: params,
      headers: new HttpHeaders({ 'Content-Type': 'application/vnd.ms-excel' }),
      observe: 'response',
      responseType: 'blob',
      withCredentials: true,
    }).subscribe(
      data => {
        let blob = new Blob([data.body]);
        let objectUrl = URL.createObjectURL(blob);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display:none');
        a.setAttribute('href', objectUrl);
        a.setAttribute('download', '校内赛团队作品及得分' + '.xlsx');
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.exportLoading = false;
      },
      error => {
        this.exportLoading = false;
        MessageHelper.error('下载失败，请稍后重试');
        console.error('校内赛团队作品及得分管理下载失败 ->', error);
      },
    );

  }

  downloadWorks() {
    const select = this.list.filter(item => item.checked);
    const params = {
      'workType.equals': '0',
      sort: ['itemId', 'core,desc'],
    };
    if (select && select.length > 0) {
      if (select.length > 1) {
        MessageHelper.warn('仅支持单个作品导出');
        return;
      } else {
        params['id.in'] = select.map(item => item.id);
        MessageHelper.success('正在打包导出，请勿刷新页面');
      }
    } else {
      MessageHelper.warn('请选择一个作品进行导出');
      return;
    }
      // if (select && select.length > 0) {
      //   params['id.in'] = select.map(item => item.id);
      // } else {
      //   MessageHelper.warn('请选择一个作品进行导出');
      //   return;
      // }
      // else {
      //   if (this.searchTeamName && this.searchTeamName.length > 0) {
      //     params['teamName.contains'] = this.searchTeamName;
      //   }
      //   if (this.searchSchoolName && this.searchSchoolName.length > 0) {
      //     params['schoolName.contains'] = this.searchSchoolName;
      //   }
      //   if (this.searchSchoolType && this.searchSchoolType !== '-1') {
      //     params['schoolType.equals'] = this.searchSchoolType;
      //   }
      //   if (this.searchRegion && this.searchRegion !== '-1') {
      //     params['location.equals'] = this.searchRegion;
      //   }
      //   if (this.searchProvinces && this.searchProvinces !== '-1') {
      //     params['province.equals'] = this.searchProvinces;
      //   }
      //   if (this.searchBimInfo && this.searchBimInfo !== '-1') {
      //     if (this.searchBimInfoEvents && this.searchBimInfoEvents !== '-1') {
      //       params['eventsId.equals'] = this.searchBimInfoEvents;
      //     } else {
      //       params['eventsId.in'] = this.bimInfoEventsList.map(item => item.id);
      //     }
      //   }
      //   if (this.searchWorkTop && this.searchWorkTop !== -1) {
      //     params['schoolTop.equals'] = this.searchWorkTop;
      //   }
      // }
      // if (this.searchWorkTop && this.searchWorkTop !== -1) {
      //   params['schoolTop.equals'] = this.searchWorkTop;
      // }
      this.downloadLoading = true;
      const url = `${this.teamUrl}/downloadWorks`;
      //需要token验证的打开下载链接的方式与不需要验证的windows.open是一样的
      this.http.get(url, {
        params: params,
        headers: new HttpHeaders({ 'Content-Type': 'application/zip' }),
        observe: 'response',
        responseType: 'blob',
        withCredentials: true,
      }).subscribe(
        data => {
          let blob = new Blob([data.body]);
          let objectUrl = URL.createObjectURL(blob);
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display:none');
          a.setAttribute('href', objectUrl);
          a.setAttribute('download', '校内赛团队作品' + '.zip');
          a.click();
          URL.revokeObjectURL(objectUrl);
          this.downloadLoading = false;
        },
        error => {
          this.downloadLoading = false;
          MessageHelper.error('下载失败，请稍后重试');
          console.error('校内赛团队作品下载失败 ->', error);
        },
      );
  }
}
