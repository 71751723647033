import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';
import { Province } from './provinces.model';
import {ThsBaseService } from  '../../../../shared/components/thsBaseService';
import { HttpAddressService } from '../../../../shared/session/http-address.service';
import { Identifiers } from '@angular/compiler';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProvinceService extends ThsBaseService<Province> {
    constructor(http: HttpClient, httpAddressService: HttpAddressService) {
        super(http, httpAddressService.BimServe + `/provinces`);
    }



    getUserProvinces(): Observable<HttpResponse<Province[]>> {
        let url = this.resourceUrl + '/getUserProvinces';
        return this.http.get<Province[]>(url, {observe: 'response'});
    }

    /**
     * 获取当前用户类型以及用户负责的省份数据
     * @returns 
     */
    getUserTypeAndProvinces(): Observable<HttpResponse<Province[]>> {
        let url = this.resourceUrl + '/user-provinces';
        return this.http.get<Province[]>(url, {observe: 'response'});
    }
}

