import { Component,Inject, TemplateRef } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService, UploadFile } from 'ng-zorro-antd';
import { BimInfoSchoolsService } from './bim-info-schools.service';
import { BimSchools,SchoolPerson } from '../../school/bim-schools.model';
import { BimSchoolsService } from '../../school/bim-schools.service';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimInfoSchoolsEditComponent} from './edit/edit.component';
import { BimInfoSchoolsAddProvinceComponent } from './add-province/add.component';

import { UserService } from "../../../../admin/system/users/users.service";
import {ProvinceService} from '../../provinces/provinces.service';
import {UcommonService} from '../../ucommon//ucommon.server';
import { BimInfoSchools } from './bim-info-schools.model';
import { BimInfoEventsService } from '../bim-info-events/bim-info-events.service';
import { isNullOrUndefined } from 'util';
import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { HttpAddressService } from '@shared/session/http-address.service';
import { BimTeamsService } from '../bim-teams/bim-teams.service';
import { environment } from '@env/environment';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';

@Component({
  selector: 'app-bim-biminfoschools',
  templateUrl: './bim-info-schools-list.component.html',
  styleUrls: ['./bim-info-schools-list.component.less']
})
export class BimInfoSchoolsListComponent implements OnInit {
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过','4':'未审核'};
  public belongTypeList = {'':'全部','0': '985',  '1' : '211', '2' : '其他'};
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimSchools[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];
  bimEventsList=[];

  locationListAll = [];
  provinceListAll = [];

  userLocationList = [];
  userProvinceList=[];
 
  entityId:string;
  entityName:String;
  accountName:String;

  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  public bimType = null;//大赛类型 bim全国赛 province 省赛 enterprise 行业赛
  curBimEventId:string;

  isloadding = false;
  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;
  
  isAdmin = false;

  queryParams = {
    // 查询搜索框的字段要预置
    'bimInfoId':'',
    'name': '',
    'schoolType': '',
    'location': '',
    'province': '',
    'audit': '',
    'belongType': '',

    // sort固定
     sort: ['create_time,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '学校名称', index: 'name' },
    { title: '学制', index: 'schoolType' , width: '8%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },
    { title: '类型', index: 'belongType' , width: '8%',
      format: (item: any) => {
        if (item.belongType === '1') {
          return '211';
        } else if (item.belongType === '0') {
          return '985';
        } else if (item.belongType === '2') {
          return '其他';
        }else{
          return item.belongType;
        }
      }
    },
    { title: '区域', index: 'location' , width: '6%',
      format: (item: any) => {
         return this.findLocationName(item.location);
      }
    },
    { title: '省份', index: 'province' , width: '8%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
    { title: '邮箱', index: 'email' , width: '12%'},
    { title: '注册码', index: 'regCode' , width: '10%'},
    { title: '赛事类别', index: 'type' , width: '10%',
    format: (item: any) => {
      return this.formatBimTypeName(item.type); 
    }},
    //{ title: '注册码发送状态', index: 'sendMail' , width: '10%'},
    
    { title: '状态', index: 'audit' , width: '6%',
      format: (item: any) => {
        if (item.audit === 1) {
          return '通过';
        } else if (item.audit === 0) {
          return '未通过';
        } else{
          return '未审核';
        }
      }
    },

    // {
    //   title: '操作', className: 'text-center', width: '10%',
    //   buttons: [
    //     // { text: '编辑', type: 'modal', component: BimSchoolsEditComponent, paramName: 'record', click: () => this.getDataList(), },
    //     // { text: '编辑',  click: (item:any) => this.edit(item), },
    //     { text: '取消参赛', click: (item: any) => { this.remove(item); } },
    //     // { text: '审核通过',  click: (item:any) => this.audit(item,1), },
    //     // { text: '取消审核',  click: (item:any) => this.audit(item,0), },
    //     // { text: '发送注册码',  click: (item:any) => this.sendRegCode(item), },
    //   ],
    // }
  ];
  
  // 上传 弹框显示
  public uploadIsVisible = false;
  // 导入工作量 excel
  public excelFile: UploadFile[] = [];
  // 是否上传中
  public excelUploading = false;
  // 导入接口
  public importUrl = '';
  
  public importBimEventsList = [];
  public importEventId = null;

  public repeatDetailList = [];
  public errorPhoneList = [];
  public errorQQList = [];
  public nullSchoolList = [];
  public nullDetailList = [];
  public nullSchoolNameList = [];
  public specialStrList = [];
  
  @ViewChild('modalWarning')
  modalWarning: TemplateRef<any>;

  // 上传 弹框显示
  public exportIsVisible = false;
  public wordExporting = false;
  public exportBimEventsList = [];
  public exportEventId = null;

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public httpClient: HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimInfoSchoolsService: BimInfoSchoolsService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimTeamsService: BimTeamsService,
    public bimSchoolsService:BimSchoolsService,
    private fileUploadService: FileUploadService,
    public httpAddressService: HttpAddressService,
    public bimInfoEventsService: BimInfoEventsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;
    this.importUrl = httpAddressService.BimServe + '/bim-schools/importBimSchool';
    // this.importUrl = 'http://127.0.0.1:15034/ths-bim-hyl/api/bim-schools/importBimSchool';

  }

  ngOnInit() {
    if(this.accountName === 'admin')
    {
      this.isAdmin = true;
      this.loadLocationList();
      this.loadprovinceList();

      //this.getDataList();
    }else{
      this.getUserProvinces();
    }


    

    
  }

/**
 * 获取用户地区信息
 */
  getUserProvinces(){
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
      //  this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      // this.st.reset();
      this.page = 0;
      Object.keys(q).forEach(function (key) {
        q[key] = '';
      });
      this.queryParams["bimInfoEventId"] = '-1';
      this.provinceList = [].concat(this.provinceListAll);

    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    if (this.queryParams["bimInfoEventId"] == '-1') {
      delete copyParams["bimInfoEventId"];
    }


    copyParams["bimInfoId"] = this.currentBimInfo.id;
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    copyParams['sort'] = ['create_time,desc']

    let tmplocation = copyParams['location'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
      
      // copyParams['location'] = this.userLocationList.join(",");
    }

    let tmpprovince = copyParams['province'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province'] = this.userProvinceList.join(",");
    }

    this.bimSchoolsService.findPageBimInfoSchools(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  // /**
  // * 新增页面
  // */
  add() {
    this.modal
      .static(BimInfoSchoolsEditComponent,{
        bimInfoType: this.currentBimInfo.type
      })
      //      .pipe(filter(w => w === true))
      .subscribe((res) => {
        debugger;
        if(res !=null && res.length > 0)
        { let schoolIds = "";
            res.forEach(schoolInfo => {
              if(schoolIds === ""){
                schoolIds =  schoolInfo.id;
              }else{
                schoolIds = schoolIds + "," + schoolInfo.id;
              }
                // let bimInfoSchool = new BimInfoSchools();
                // bimInfoSchool.id = null;
                // bimInfoSchool.creator = this.entityId;
                // bimInfoSchool.createTime = new Date();
                // bimInfoSchool.updator = this.entityId;
                // bimInfoSchool.updateTime = new Date();
                // bimInfoSchool.bimInfoId = this.currentBimInfo.id;
                // bimInfoSchool.schoolId = schoolInfo.id;
                // this.bimInfoSchoolsService.create(bimInfoSchool).subscribe(res => {
                //    console.log(res);
                // });;
            });

             this.bimInfoSchoolsService.addSchools(this.currentBimInfo.id, schoolIds).subscribe(resBimInfoSchool => {
                    console.log(resBimInfoSchool);
                    this.getDataList();
                });
        }
       
      });
  }


  // /**
  // * 添加学校
  // */
  create() {
    this.modal
      .static(BimInfoSchoolsAddProvinceComponent,{ 
        record: { 
          id: null,
          userDefine1: this.currentBimInfo.id,
          bimInfoId: this.currentBimInfo.id,
          bimInfoType: this.currentBimInfo.type
        },
        isAdd: true
      })
      //      .pipe(filter(w => w === true))
      .subscribe((res) => {
        if(res){ 
          this.getDataList();
        }
      });
  }

  editData(item){
    this.modal
    .static(BimInfoSchoolsAddProvinceComponent,{ 
      record: { 
        ...item,
        bimInfoId: this.currentBimInfo.id,
        bimInfoType: this.currentBimInfo.type
      },
      isAdd: false
    })
    //      .pipe(filter(w => w === true))
    .subscribe((res) => {
      if(res){ 
        this.getDataList();
      }
    });
  }


   /**
  * 删除
  */
 remove(res: any) {
  this.modalService.confirm({
    nzTitle: '确认删除?',
    nzContent: '',
    nzOkText: '是',
    nzOkType: 'danger',
    nzOnOk: () => {
      this.bimInfoSchoolsService.deleteBimInfoSchools(this.currentBimInfo.id,res.id)
        .subscribe(() => {
          this.page = 0;
          this.getDataList();
          this.st.clearCheck();
        });
    },
    nzCancelText: '否',
    nzOnCancel: () => console.log('Cancel')
  });
}

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    this.locationListAll = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      this.userService.queryRegionList(dataParams).subscribe(res => {
        
        if (res && res.body) {
          this.locationList = res.body;
          this.locationListAll = res.body;
        }
      });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    this.provinceListAll = [];
    
    this.userService.queryProvinceList({
      'id.in':this.userProvinceList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    }).subscribe(res => {
      if (res && res.body) {
        const data = res.body;
         this.provinceList = data;
         this.provinceListAll = data;
      }
    });
  }


  findLocationName(location){
     let tmpList = this.locationListAll.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  findProvinceName(location){
    let tmpList = this.provinceListAll.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }


 exportExcel(){
  let copyParams = {};
  const q = this.queryParams;
 
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' &&　q[key] !== null) {
        copyParams[key] = q[key];
      }
    });


  let tmplocation = copyParams['location'];

  if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0)  ))
  {   
    //delete copyParams['location.contains'];
    // copyParams['location'] = this.userLocationList.join(",");
  }

  let tmpprovince = copyParams['province'];

  if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0) ))
  {
    //delete copyParams['province.contains'];
    copyParams['province'] = this.userProvinceList.join(",");
  }

  if (this.queryParams["bimInfoEventId"] == '-1') {
    delete copyParams["bimInfoEventId"];
  }
  this.bimSchoolsService.exportBimInfoSchoolExcel(copyParams);
 }


//  /**
//   * 重新生成注册码
//   */
//  regenerateRegCode(){
//   this.modalService.confirm({
//     nzTitle: '重新生成会将之前已经生成的注册码清除，是否重新生成?',
//     nzContent: '',
//     nzOkText: '是',
//     nzOkType: 'danger',
//     nzOnOk: () => {
//       debugger;
//        let schoolIds = [];
//        this.selectedRows.forEach(item=>{
//           schoolIds.push(item.id);
//        });

//       // this.bimSchoolsService.regenerateRegCode(schoolIds)
//       //   .subscribe(() => {
//       //     this.getDataList();
//       //     this.st.clearCheck();
//       //   });
//     },
//     nzCancelText: '否',
//     nzOnCancel: () => console.log('Cancel')
//   });
//  }
 

 

//   sendRegCode(record){
//     let freqContactList = []
//     if(record.freqContact!= null && record.freqContact.length > 0)
//     {
//       freqContactList =  JSON.parse( this.ucommonService.ReplaceHtml(record.freqContact));
//     }
//     if(freqContactList == null || freqContactList==undefined || freqContactList.length<= 0 )
//     {
//       this.msg.warning('没有填写大赛联系人，不能发送注册码！');
//       return false;
//     }

//     let tmpMobile="";
//     let tmpEmail = "";

//     for(let  i=0; i< freqContactList.length; i++)
//     {
//       if(!this.ucommonService.ValidatorsMobile(freqContactList[i].mobile))
//         {
//           this.msg.warning('大赛联系人的手机号码有不正确的填写，不能发送注册码！');
//           return false;
         
//         }else{
//           if(tmpMobile.length <= 0)
//           {
//             tmpMobile = freqContactList[i].mobile;
//           }else{
//             tmpMobile = tmpMobile + "," + freqContactList[i].mobile;
//         }
//       }

//       if(!this.ucommonService.ValidatorsEmail(freqContactList[i].email))
//         {
//           this.msg.warning('大赛联系人的电子邮箱（EMail）有不正确的填写，不能发送注册码！');
//           return false;
         
//         }else{
//           if(tmpEmail.length <= 0)
//           {
//             tmpEmail = freqContactList[i].email;
//           }else{
//             tmpEmail = tmpEmail + "," + freqContactList[i].email;
//         }
//       }


//     }

//     // this.bimSchoolsService.sendSMSCode(tmpMobile, record.regCode).subscribe(res => {
//     //     if (!res || !res.success || res.success !== true) {
//     //       this.msg.error(res.msg);
//     //       record.sendMail = "发送失败, 失败原因：" + res.msg;
//     //     }else{
//     //        record.sendMail = "发送成功"; 
//     //     }

//     //     this.bimSchoolsService.update(record).subscribe(res => {
//     //          this.getDataList();
//     //     });

     
//     // });

//    // this.bimSchoolsService.sendEmailCode(tmpEmail, record.regCode);
//   }


  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    this.bimType = bimInfo.type;
    if (bimInfo.type === 'province') {
      const selColumns = this.columns.filter(w => w.title ==='操作');
      if(!selColumns || selColumns.length == 0){
        this.columns = [...this.columns,
          {
            title: '操作', className: 'text-center', width: '10%',
            buttons: [
              { text: '编辑',  click: (item: any) => { this.editData(item); } },
              { text: '<span class=\"btn-remove\" title=\"删除\">删除</span>', click: (item: any) => { this.remove(item); } },
            ],
          }
        ];
      }
    }else{
      this.columns = this.columns.filter(w => w.title !='操作');
    }
    this.queryParams["bimInfoId"] = this.currentBimInfo.id;
    // this.getDataList();
    this.getEventsDataList();
  }

  queryData(){
    this.page = 0;
    this.getDataList();
  }

  changeLocation(event){
    if(event){
      const data = this.provinceListAll.filter(w=>w.fkregionId === event);
      this.provinceList = data;
    }else{
      this.provinceList = this.provinceListAll;
    }
    
    this.queryParams['province.contains'] = '';
    this.queryParams['province'] = '';
    
    this.queryData();
  }



  searche(){
    this.page = 0;
    this.getDataList();
  }

  

/**
 * 获取赛事赛项
 * @param isReset 
 */
public getEventsDataList(isReset?: boolean) {
  let copyParams = {sort: ['createTime,desc', 'eventsType,desc']};
  const q = this.queryParams;

  copyParams['page'] = 0;
  copyParams['size'] = 1000;
  copyParams['bimInfoId.contains'] = this.currentBimInfo.id;
  copyParams['isDelete.equals'] = 0;

  this.bimInfoEventsService.query(copyParams)
    .subscribe((res: any) => {
        if(res.ok && res.body.length >0)
        {
          const data = res.body.filter(e => e.eventsType == 0);
          this.exportBimEventsList = JSON.parse(JSON.stringify(data));
          this.importBimEventsList = JSON.parse(JSON.stringify(res.body));
          this.bimEventsList = res.body;
          this.bimEventsList.push({
            id: '-1',
            name: '全部'
          });
          this.curBimEventId = '-1';
          this.queryParams["bimInfoEventId"] = '-1';
          this.getDataList();
        }else{
          this.exportBimEventsList = [];
          this.importBimEventsList = [];
          this.bimEventsList = [];
          this.curBimEventId = "-1";
          this.queryParams["bimInfoEventId"] = "-1";
          this.getDataList();
        }
      });   
}

/**
 * 赛项选择框改变
 * @param $event 
 */
bimEventsChange($event){
  this.curBimEventId = $event;
  this.queryParams["bimInfoEventId"] = this.curBimEventId;
  this.getDataList();
}

  formatBimTypeName(type){
    switch (type) {
      case 1:
      case 5:
        return '团队赛';
      case 2:
      case 6:
        return '个人赛';
      case 3:
      case 7:
        return '团队赛/个人赛';
      case 4:
        return '';
      default:
        break;
    }
  }

  // 上传之前
  beforeUpload = (file: UploadFile): boolean => {
    const isXlsx = file.name.endsWith('xlsx') || file.name.endsWith('xls');
    if (!isXlsx) {
      this.msg.error('仅允许上传xlsx或xls格式文件');
    }
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      this.msg.error('文件不能大于50M');
    }
    if (isXlsx && isLt2M) {
      this.excelFile.push(file);
    }
    return false;
  }

  // 打开上传弹框
  openUploadnModal() {
    this.uploadIsVisible = true;
  }

  // 关闭上传弹框
  handleCancel() {
    this.excelFile = [];
    this.importEventId = null;
    this.uploadIsVisible = false;
    this.excelUploading = false;

    this.exportEventId = false;
    this.exportIsVisible = false;
    this.wordExporting = false;
  }
  // 导入参赛学校信息
  handleUpload(): void {
    if (this.currentBimInfo.type !== 'province') {
      this.msg.remove();
      this.msg.info('请选择省级赛事进行导入！');
      return;
    }
    if (isNullOrUndefined(this.importEventId)) {
      this.msg.remove();
      this.msg.info('请选择赛项！');
      return;
    }
    if (this.excelFile.length === 0) {
      this.msg.remove();
      this.msg.info('请选择导入文件！');
      return;
    }
    this.excelUploading = true;
    const formData = new FormData();
    this.excelFile.forEach((file: any) => {
      formData.append('file', file);
      formData.append('bimInfoId', this.currentBimInfo.id);
      formData.append('eventId', this.importEventId);
    });
    const req = new HttpRequest('POST', this.importUrl, formData, {});
    this.httpClient
      .request(req)
      .pipe(filter(e => e instanceof HttpResponse))
      .subscribe(
        (event: any) => {
          const result = event.body;
          this.excelUploading = false;
          if (result.message !== 'success') {
            let title = '文件错误';
            let content = result.message;
            if (result.message === 'errorTem') {
              content = '上传模板错误，请使用正确的模板';
            } else if (result.message === 'errorLogin') {
              content = '获取登录用户失败，请重新登录';
            } else if (result.message === 'errorBimInfo') {
              content = '未获取到大赛信息，请重试';
            } else {
              this.repeatDetailList = result.repeatDetailList;
              this.errorPhoneList = result.errorPhoneList;
              this.errorQQList = result.errorQQList;
              this.nullSchoolList = result.nullSchoolList;
              this.nullDetailList = result.nullDetailList;
              this.nullSchoolNameList = result.nullSchoolNameList;
              this.specialStrList = result.specialStrList;
              title = '其他数据已上传成功：';
              content = this.modalWarning;
            }
            this.modalService.warning({
              nzTitle: title,
              nzContent: content,
              nzWidth: '60vh'
            });
          } else {
            this.msg.success('导入成功');
            this.handleCancel();
            this.getDataList();
          }
          this.excelFile = [];
        },
        err => {
          this.excelUploading = false;
          this.msg.error('upload failed.');
        })
  }

  // 打开上传弹框
  openExportModal() {
    if (this.selectedRows.length == 0) {
      this.msg.warning('请先选择一个学校');
      return;
    }
    if (this.selectedRows.length > 1) {
      this.msg.warning('只可选择一个学校');
      return;
    }
    this.exportIsVisible = true;
  }
  
  exportTeamEnrollWord() {
    if (this.selectedRows.length == 0) {
      this.msg.warning('请先选择一个学校');
      return;
    }
    if (this.selectedRows.length > 1) {
      this.msg.warning('只可选择一个学校');
      return;
    }
    if (isNullOrUndefined(this.exportEventId)) {
      this.msg.remove();
      this.msg.info('请选择赛项！');
      return;
    }
    this.wordExporting = true;
    this.bimTeamsService.exportTeamEnrollWord({
      schoolId: this.selectedRows[0].id,
      bimInfoName: this.currentBimInfo.name,
      bimInfoEventId: this.exportEventId
    }).subscribe((dRes: any) => {
      const data = dRes.body.data;
      if (data && data.success) {
        this.exportEventId = null;
        this.exportIsVisible = false;
        this.wordExporting = false;
        const fileId = data.data;
        this.successModelView('生成成功，正在下载');
        this.downFile(fileId);
        setTimeout(() => {
          this.fileUploadService.delete(fileId).subscribe();
        }, 15000);
      } else {
        this.wordExporting = false;
        this.warningModelView(data.message);
      }
    });
  }

  downFile(fileId) {
    const a = document.createElement('a');
    // tslint:disable-next-line:max-line-length
    const url = environment.FILE_URL + '/thsadmin/api/sys-files/download/' + fileId;
    a.href = url;
    // a.download = filename;
    a.click();
  }

  successModelView(msg: string) {
    this.modalService.success({
      nzTitle: msg,
      nzContent: '',
      nzOkText: '确定',
      nzOkType: 'success',
    });
  }

  warningModelView(msg: string) {
    this.modalService.warning({
      nzTitle: msg,
      nzContent: '',
      nzOkText: '确定',
      nzOkType: 'warning',
    });
  }
}
