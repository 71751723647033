import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';

import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { BimSchoolsService } from './bim-schools.service';
import { BimSchools,SchoolPerson } from './bim-schools.model';
import { APP_PERMISSIONS } from '@shared/app.permissions';

import {BimSchoolsEditComponent} from './edit/edit.component';
import { UserService } from "../../../admin/system/users/users.service";
import {ProvinceService} from '../provinces/provinces.service';
import {UcommonService} from '../ucommon//ucommon.server';
import { BimInfoSchoolsService } from '../bim-info/bim-info-schools/bim-info-schools.service';
import { MessageHelper } from '@shared/helpers/MessageHelper';

@Component({
  selector: 'app-bim-bimschools',
  templateUrl: './bim-schools-list.component.html',
  styleUrls: ['./bim-schools-list.component.less']
})
export class BimSchoolsListComponent implements OnInit {
  public schoolTypeList = {'':'全部','0': '本科',  '1' : '专科'};

  public schoolAuditeList = {'':'全部','0': '未通过',  '1' : '通过','4':'未审核'};
  public belongTypeList = {'':'全部','0': '985',  '1' : '211', '2' : '其他'};
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list: BimSchools[] = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  locationList=[];
  provinceList=[];

  locationListAll = [];
  provinceListAll = [];

  userLocationList = [];
  userProvinceList=[];

  isInitLocation = false;
 
  entityId:string;
  entityName:String;
  accountName:String;

  isloadding = false;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'name.contains': '',
    'schoolType.contains': '',
    'location.contains': '',
    'province.contains': '',
    'audit.equals': '',
    'belongType.contains': '',
    'isDelete.equals': '0',

    // sort固定
     sort: ['createTime,desc']
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '学校名称', index: 'name' , width: '15%'},
    { title: '学制', index: 'schoolType' , width: '5%',
      format: (item: any) => {
        if (item.schoolType === '1') {
          return '专科';
        } else if (item.schoolType === '0') {
          return '本科';
        } else if (item.schoolType === '2') {
          return '中职';
        } else{
          return item.schoolType;
        }
      }
    },
    { title: '类型', index: 'belongType' , width: '5%',
      format: (item: any) => {
        if (item.belongType === '1') {
          return '211';
        } else if (item.belongType === '0') {
          return '985';
        } else if (item.belongType === '2') {
          return '其他';
        }else{
          return item.belongType;
        }
      }
    },
    { title: '区域', index: 'location' , width: '5%',
      format: (item: any) => {
         return this.findLocationName(item.location);
      }
    },
    { title: '省份', index: 'province' , width: '5%',
      format: (item: any) => {
        return this.findProvinceName(item.province); 
      }
    },
    { title: '邮箱', index: 'email' , width: '10%'},
    { title: '注册码', index: 'regCode' , width: '5%'},
    { title: '注册码发送状态', index: 'sendMail' , width: '10%'},
    
    { title: '状态', index: 'audit' , width: '5%',
      format: (item: any) => {
        if (item.audit === 1) {
          return '通过';
        } else if (item.audit === 0) {
          return '未通过';
        } else{
          return '未审核';
        }
      }
    },
      // { title: '截图', index: 'imgurl' },
    // { title: '权重', index: 'per', width: '12%' },
    // { title: '分类', index: 'type', width: '12%' },
    // { title: '序号', index: 'orderSn', width: '12%' },
    {
      title: '操作', className: 'text-center', width: '35%',
      buttons: [
        // { text: '编辑', type: 'modal', component: BimSchoolsEditComponent, paramName: 'record', click: () => this.getDataList(), },
        { text: '编辑',  click: (item:any) => this.edit(item), },
        { text: '删除',  click: (item: any) => { this.remove(item); } },
        { text: '审核通过',  click: (item:any) => this.audit(item,1), },
        { text: '取消审核',  click: (item:any) => this.audit(item,0), },
        { text: '发送注册码',  click: (item:any) => this.sendRegCode(item), },
      ],
    }
  ];

  public currentBimInfo = null;
  
  public bimInfoStatus = 1;//-1 全部;

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    public bimSchoolsService: BimSchoolsService,
    public userService:UserService,
    public provinceService:ProvinceService,
    public ucommonService:UcommonService,
    public bimInfoSchoolsService: BimInfoSchoolsService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;


  }

  ngOnInit() {
    if (this.accountName === 'admin') {
      // this.loadLocationList();
      // this.loadprovinceList();
      
      // this.getDataList();

      Promise.all([
        this.loadLocationList(),
        this.loadprovinceList()
      ]).then(res=>{ 
        this.getDataList(); 
      }); 
    } else {
      if (this.isInitLocation) {
        this.getDataList();
      } else {
        this.getUserProvinces();
      }
    }


    

    
  }

/**
 * 获取用户地区信息
 */
  getUserProvinces(){
    this.isInitLocation = true;
     this.userProvinceList = [];
     this.userLocationList = [];
    this.provinceService.getUserProvinces()
    .subscribe((res: any) => {
      
        res.body.forEach(element => {
            this.userProvinceList.push(element.id);
            this.userLocationList.push(element.fkregionId);

            
        });
        this.loadLocationList();
        this.loadprovinceList();
        this.getDataList();

        //this.userProvinceList = res.body;
        console.log(res.body);
    });
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  public getDataList(isReset?: boolean) {
    this.isloadding = true;
     let copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      Object.keys(q).forEach(function (key) {
        if(key == 'sort'){
          copyParams[key] = q[key];
          return true;
        }
        q[key] = '';
      });
      this.provinceList = [].concat(this.provinceListAll);
      this.page = 0;
      // this.st.reset();
    } else {
      Object.keys(q).forEach(function (key) {
        if (q[key] !== '' &&　q[key] !== null) {
          copyParams[key] = q[key];
        }
      });
    }

    copyParams['page'] = this.page;
    copyParams['size'] = this.size;

    if(copyParams['audit.equals'] == '4'){
      //未审核
      delete copyParams['audit.equals'];
      copyParams['audit.specified'] = false;
    }

    let tmplocation = copyParams['location.contains'];

    if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0) || isReset === true))
    {   
      //delete copyParams['location.contains'];
      copyParams['location.in'] = this.userLocationList;
    }

    let tmpprovince = copyParams['province.contains'];

    if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0)|| isReset === true))
    {
      //delete copyParams['province.contains'];
      copyParams['province.in'] = this.userProvinceList;
    }
    copyParams['isDelete.equals'] = '0';
    this.bimSchoolsService.query(copyParams)
      .subscribe((res: any) => {

      this.list = res.body;
      this.total = res.headers.get('X-Total-Count');
      this.isloadding = false;
      });
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    this.getDataList();
  }

  /**
  * 排序变动事件
  * @param
  */
  sortChange(event) {
    const array = this.queryParams['sort'];
    const length = array.length;
    let isInArray = false;
    let value = null;
    if (event.value === 'descend') {
      value = 'desc';
    } else if (event.value === 'ascend') {
      value = 'asc';
    }
    for (let i = 0; i < length; i++) {
      if (array[i].startsWith(event.column.indexKey)) {
        if (value == null) {
          array.splice(i, 1);
          isInArray = true;
          break;
        } else {
          array[i] = event.column.indexKey + ',' + value;
          isInArray = true;
          break;
        }
      }
    }
    if (value != null && !isInArray) {
      array.push(event.column.indexKey + ',' + value);
    }
    // 排序改变时，simpleTable会重置页码
    this.page = 0;
    this.getDataList();
  }


  /**
  * 新增页面
  */
  add() {
    this.modal
      .static(BimSchoolsEditComponent, { 
        record: { 
          id: null,
          // userDefine1: this.currentBimInfo.id,
          // userDefine4: this.currentBimInfo.type
        },
        isAdd: true,
        userLocationList:this.userLocationList, 
        userProvinceList:this.userProvinceList,  
      }, 1000)
      //      .pipe(filter(w => w === true))
      .subscribe(() => {
        this.getDataList();
      });
  }

  edit(record){
    this.modal
    .static(BimSchoolsEditComponent, { 
      record: record,
      isAdd: false,
      userLocationList:this.userLocationList, 
      userProvinceList:this.userProvinceList  
    },1000)

    .subscribe((res) => {
      if(res === true)
      {
        this.getDataList();
      }
      
    });
  }

  audit(record, flag){
     record.audit=flag;
     this.bimSchoolsService.update(record).subscribe(res => {
        if (record  ) {
          if(flag === 1)
          {
            this.msg.info('审核成功！');
          }else{
            this.msg.info('取消审核成功!');
          }
          
          this.getDataList();
        } else {
          if(flag === 1)
          {
            this.msg.info('审核失败！');
          }else{
            this.msg.info('取消审核失败!');
          }
        }
      });
    
  }



  /**
  * 删除
  */
  remove(res: any) {
    // 判断是否参赛
    // 判断是否有团队报名
    // 判断是否有个人报名
      this.modalService.confirm({
        nzTitle: '确认删除?',
        nzContent: '',
        nzOkText: '是',
        nzOkType: 'danger',
        nzOnOk: () => {
          this.bimSchoolsService.delete(res.id).subscribe((item) => {
            if (item && item.body) {
              if (item.body.success) {
                this.msg.info(item.body.message);
              } else {
                MessageHelper.warn(item.body.message);
              }
              this.page = 0;
              this.getDataList();
              this.st.clearCheck();
            }
          });
        },
        nzCancelText: '否',
        nzOnCancel: () => console.log('Cancel')
      });
  }

  /**
  * 批量删除
  */
  removeBatch() {
    if (this.selectedRows.length < 1) return this.msg.warning('请先选择您要删除的信息！');
    let flag = false;
    this.selectedRows.forEach((ele) => {
      if (ele.userName === 'admin') {
        this.msg.info('管理员账号不能删除');
        flag = true;
      }
    });
    if (flag) {
      return ;
    }
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.bimSchoolsService.delete(this.selectedRows.map(i => i.id))
          .subscribe(() => {
            this.getDataList();
            this.st.clearCheck();
          });
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }



    /**
   * 加载区域列表
   */
  loadLocationList(){
    this.locationList = [];
    this.locationListAll = [];
    // 加载区域与省份数据
    const dataParams = {
      'id.in':this.userLocationList,
      'size' : '10000',
      'sort': 'orderNum,asc'
    };

      // this.userService.queryRegionList(dataParams).subscribe(res => {
        
      //   if (res && res.body) {
      //     this.locationList = res.body;
      //     this.locationListAll = res.body;
      //   }
      // });

    return new Promise<void>(resolve => {
      this.userService.queryRegionList(dataParams).subscribe(res => {
      
        if (res && res.body) {
          this.locationList = res.body;
          this.locationListAll = res.body;
        }
        resolve();
      })
    });
  }


  
  /**
   * 加载省份列表
   */
  loadprovinceList(){
    this.provinceList = [];
    this.provinceListAll = [];
    
    // this.userService.queryProvinceList({
    //   'id.in':this.userProvinceList,
    //   'size' : '10000',
    //   'sort': 'orderNum,asc'
    // }).subscribe(res => {
    //   if (res && res.body) {
    //     const data = res.body;
    //      this.provinceList = data;
    //      this.provinceListAll = data;
    //   }
    // });

    return new Promise<void>(resolve => {
      this.userService.queryProvinceList({
        'id.in':this.userProvinceList,
        'size' : '10000',
        'sort': 'orderNum,asc'
      }).subscribe(res => {
        if (res && res.body) {
          const data = res.body;
           this.provinceList = data;
           this.provinceListAll = data;
        }
        resolve();
      });
    });
  }


  findLocationName(location){
     let tmpList = this.locationListAll.filter(d=>d.id===location);
     if(tmpList.length >0)
     {
        return tmpList[0].regionName;
     }else{
       return '';
     }
  }

  findProvinceName(location){
    let tmpList = this.provinceListAll.filter(d=>d.id===location);
    if(tmpList.length >0)
    {
       return tmpList[0].provinceName;
    }else{
      return '';
    }
 }


 exportExcel(){
  let copyParams = {};
  const q = this.queryParams;
 
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' &&　q[key] !== null) {
        copyParams[key] = q[key];
      }
    });


  let tmplocation = copyParams['location.contains'];

  if(this.accountName !=='admin' &&(( tmplocation ==null || tmplocation==undefined ||tmplocation.length <= 0)  ))
  {   
    //delete copyParams['location.contains'];
    copyParams['location.in'] = this.userLocationList;
  }

  let tmpprovince = copyParams['province.contains'];

  if(this.accountName !=='admin' && ((tmpprovince ==null || tmpprovince==undefined || tmpprovince.length <= 0) ))
  {
    //delete copyParams['province.contains'];
    copyParams['province.in'] = this.userProvinceList;
  }

  this.bimSchoolsService.exportExcel(copyParams);
 }


 /**
  * 重新生成注册码
  */
 regenerateRegCode(){
  this.modalService.confirm({
    nzTitle: '重新生成会将之前已经生成的注册码清除，是否重新生成?',
    nzContent: '',
    nzOkText: '是',
    nzOkType: 'danger',
    nzOnOk: () => {
      debugger;
       let schoolIds = [];
       this.selectedRows.forEach(item=>{
          schoolIds.push(item.id);
       });

      this.bimSchoolsService.regenerateRegCode(schoolIds)
        .subscribe(() => {
          this.getDataList();
          this.st.clearCheck();
        });
    },
    nzCancelText: '否',
    nzOnCancel: () => console.log('Cancel')
  });
 }
 

 

  sendRegCode(record){
    let freqContactList = []
    if(record.freqContact!= null && record.freqContact.length > 0)
    {
      freqContactList =  JSON.parse( this.ucommonService.ReplaceHtml(record.freqContact));
    }
    if(freqContactList == null || freqContactList==undefined || freqContactList.length<= 0 )
    {
      this.msg.warning('没有填写大赛联系人，不能发送注册码！');
      return false;
    }

    let tmpMobile="";
    let tmpEmail = "";

    for(let  i=0; i< freqContactList.length; i++)
    {
      if(!this.ucommonService.ValidatorsMobile(freqContactList[i].mobile))
        {
          this.msg.warning('大赛联系人的手机号码有不正确的填写，不能发送注册码！');
          return false;
         
        }else{
          if(tmpMobile.length <= 0)
          {
            tmpMobile = freqContactList[i].mobile;
          }else{
            tmpMobile = tmpMobile + "," + freqContactList[i].mobile;
        }
      }

      if(!this.ucommonService.ValidatorsEmail(freqContactList[i].email))
        {
          this.msg.warning('大赛联系人的电子邮箱（EMail）有不正确的填写，不能发送注册码！');
          return false;
         
        }else{
          if(tmpEmail.length <= 0)
          {
            tmpEmail = freqContactList[i].email;
          }else{
            tmpEmail = tmpEmail + "," + freqContactList[i].email;
        }
      }


    }

    this.bimSchoolsService.sendSMSCode(tmpMobile, record.regCode).subscribe(res => {
        if (!res || !res.success || res.success !== true) {
          this.msg.error(res.msg);
          record.sendMail = "发送失败, 失败原因：" + res.msg;
        }else{
           record.sendMail = "发送成功"; 
        }

        this.bimSchoolsService.update(record).subscribe(res => {
             this.getDataList();
        });

     
    });

   // this.bimSchoolsService.sendEmailCode(tmpEmail, record.regCode);
  }

  queryData(){
    this.page = 0;
    this.getDataList();
  }

  changeLocation(event){
    if(event){
      const data = this.provinceListAll.filter(w=>w.fkregionId === event);
      this.provinceList = data;
    }else{
      this.provinceList = this.provinceListAll;
    }
    
    this.queryParams['province.contains'] = '';

    this.queryData();
  }

  clickContest(bimInfo) {
    this.currentBimInfo = bimInfo;
    if (bimInfo.type === 'province') {
      this.columns = [
        { title: '', index: 'id', type: 'checkbox' },
        { title: '学校名称', index: 'name' , width: '15%'},
        { title: '类型', index: 'schoolType' , width: '5%',
          format: (item: any) => {
            if (item.schoolType === '1') {
              return '专科';
            } else if (item.schoolType === '0') {
              return '本科';
            } else if (item.schoolType === '2') {
              return '中职';
            } else{
              return item.schoolType;
            }
          }
        },
        { title: '院系', index: 'college' , width: '20%'},
        { title: '通讯地址', index: 'address' , width: '20%'},
        { title: '注册码发送状态', index: 'sendMail' , width: '10%'},
        { title: '状态', index: 'audit' , width: '5%',
          format: (item: any) => {
            if (item.audit === 1) {
              return '通过';
            } else if (item.audit === 0) {
              return '未通过';
            } else{
              return '未审核';
            }
          }
        },
        {
          title: '操作', className: 'text-center', width: '25%',
          buttons: [
            // { text: '编辑', type: 'modal', component: BimSchoolsEditComponent, paramName: 'record', click: () => this.getDataList(), },
            { text: '编辑',  click: (item:any) => this.edit(item), },
            { text: '删除',  click: (item: any) => { this.remove(item); } },
            { text: '审核通过',  click: (item:any) => this.audit(item,1), },
            { text: '取消审核',  click: (item:any) => this.audit(item,0), },
            { text: '发送注册码',  click: (item:any) => this.sendRegCode(item), },
          ],
        }
      ];
    } else {
      this.columns = [
        { title: '', index: 'id', type: 'checkbox' },
        { title: '学校名称', index: 'name' , width: '15%'},
        { title: '学制', index: 'schoolType' , width: '5%',
          format: (item: any) => {
            if (item.schoolType === '1') {
              return '专科';
            } else if (item.schoolType === '0') {
              return '本科';
            } else if (item.schoolType === '2') {
              return '中职';
            } else{
              return item.schoolType;
            }
          }
        },
        { title: '类型', index: 'belongType' , width: '5%',
          format: (item: any) => {
            if (item.belongType === '1') {
              return '211';
            } else if (item.belongType === '0') {
              return '985';
            } else if (item.belongType === '2') {
              return '其他';
            }else{
              return item.belongType;
            }
          }
        },
        { title: '区域', index: 'location' , width: '5%',
          format: (item: any) => {
             return this.findLocationName(item.location);
          }
        },
        { title: '省份', index: 'province' , width: '5%',
          format: (item: any) => {
            return this.findProvinceName(item.province); 
          }
        },
        { title: '邮箱', index: 'email' , width: '10%'},
        { title: '注册码', index: 'regCode' , width: '5%'},
        { title: '注册码发送状态', index: 'sendMail' , width: '10%'},
        
        { title: '状态', index: 'audit' , width: '5%',
          format: (item: any) => {
            if (item.audit === 1) {
              return '通过';
            } else if (item.audit === 0) {
              return '未通过';
            } else{
              return '未审核';
            }
          }
        },
          // { title: '截图', index: 'imgurl' },
        // { title: '权重', index: 'per', width: '12%' },
        // { title: '分类', index: 'type', width: '12%' },
        // { title: '序号', index: 'orderSn', width: '12%' },
        {
          title: '操作', className: 'text-center', width: '35%',
          buttons: [
            // { text: '编辑', type: 'modal', component: BimSchoolsEditComponent, paramName: 'record', click: () => this.getDataList(), },
            { text: '编辑',  click: (item:any) => this.edit(item), },
            { text: '删除',  click: (item: any) => { this.remove(item); } },
            { text: '审核通过',  click: (item:any) => this.audit(item,1), },
            { text: '取消审核',  click: (item:any) => this.audit(item,0), },
            { text: '发送注册码',  click: (item:any) => this.sendRegCode(item), },
          ],
        }
      ];
    }
    if(this.currentBimInfo){
      this.queryParams["userDefine1.equals"] = this.currentBimInfo.id;
    }
    
    if (this.accountName === 'admin') {
      this.getDataList();
    } else {
      if (this.isInitLocation) {
        this.getDataList();
      } else {
        this.getUserProvinces();
      }
    }
  }
}
