import { Component,Inject } from '@angular/core';
import { _HttpClient,ModalHelper } from '@delon/theme';
import { OnInit,ViewChild } from '@angular/core';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { GuidUtil } from '@shared/utils/guid.util';
import { SimpleTableComponent, SimpleTableData, SimpleTableColumn} from '@delon/abc';
import { DictService } from '@shared/components/dict/dict.service';

import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { APP_PERMISSIONS } from '@shared/app.permissions';
import { isNullOrUndefined } from 'util';
import {ThsadminFileService} from "@shared/components/f-upload/thsadmin-file.service";
import { MessageHelper } from '@shared/helpers/MessageHelper';


import { ThsNewsService } from '../ths-news/ths-news.service';
import { FileUploadService } from '@shared/components/fileUpload/fileUpload.service';
import { BimCompetitionMienEditComponent } from './edit/edit.component';
import { ACLService } from '@delon/acl';

@Component({
  selector: 'app-bim-competition-mien',
  templateUrl: './bim-competition-mien.component.html',
  styleUrls: ['./bim-competition-mien.component.less']
})
export class BimCompetitionMienComponent implements OnInit {

  contentType = 'bimCompetitionMien';//比赛风采
  public page  = 0;
  public size  = 10;
  public total ;
  public APP_PERMISSIONS = APP_PERMISSIONS;
  public list = [];
  public totalCallNo  = 0;
  public selectedRows: SimpleTableData[] = [];
  listOfOptions = [];
 
  entityId:string;
  entityName:String;
  accountName:String;

  exportExcelKeyName = "";

  public bimInfoStatus = 1;//-1 全部;
  
  public currentBimInfo = null;
  curBimEventId:string;

  isCanSave = false;
  isCanDelete = false;

  isloadding = false;
  
  allChecked = false;
  indeterminate = false;

  // HTML模板里面<simple-table>的选择器必须为st
  @ViewChild('st') st: SimpleTableComponent;

  queryParams = {
    // 查询搜索框的字段要预置
    'title.in': '',
    'author.in': '',
    'content.contains': '',
    'bimInfoId.in': '',
    // 'isDelete.equals': '0',

    // sort固定
     sort: ['isreport,desc','isDelete,asc','hit,asc']//istop 风采类型排序, isDelete是否启用，hit 分类名称排序
  };


  columns: SimpleTableColumn[] = [
    { title: '', index: 'id', type: 'checkbox' },
    { title: '风采类型', index: 'title' , width: '20%'},
    { title: '类型名称', index: 'content' , width: '30%'},
    { title: '排序', index: 'hit' , width: '10%',format:(item:any) => {
      if (!item.hit) {
        return item.isreport;
      } else {
        return item.hit;
      }
    }},
    { title: '是否启用', index: 'isDelete' , width: '10%',format:(item:any) => {
      if (item.isDelete === '0') {
        return '启用';
      } else {
        return '未启用';
      }
    }},
    {
      title: '创建时间',
      index: 'createTime',
      width: '20%',
      type: 'date'
    },
    {
      title: '操作',index: 'operator', className: 'text-center', width: '25%',
      buttons: [
        { text: '编辑',acl: false,  click: (item:any) => this.edit(item), },
        { text: '删除',acl: false, click: (item: any) => this.remove(item) },
      ]
    }
  ];

  constructor(
    public msg: NzMessageService,
    public http: _HttpClient,
    public modal: ModalHelper,
    public modalService: NzModalService,
    private thsNewsService:ThsNewsService,
    private fileUploadService: FileUploadService,
    private dictService: DictService,
    private adminFileService: ThsadminFileService,
    private aclService: ACLService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
  ) {

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.accountName = tokenService.get().account_name;

  }

  ngOnInit() {
    this.getCompMienTypes();
    this.getDataList();
  }


  /**
  * 获取数据列表
  * @param {string} url
  */
  getDataList(isReset?: boolean, isSearch?: boolean) {
    this.list = [];
    if(!this.currentBimInfo){
        return;
    }
    this.isloadding = true;
    const copyParams = this.getParams(isReset, isSearch);
    this.thsNewsService.query(copyParams).subscribe(res => {
      this.isloadding = false;
      const result = res.body;
      const datas = [];
      const datasKey = [];
      //去除重复类型
      if(result){
        result.forEach(item => {
          if(!datasKey.includes(item.title)){
            datasKey.push(item.id);
            datas.push(item);
          }
        })
      }

      this.list = datas;
      if (this.list.length === 0 && this.page > 0) {
        this.page--;
        this.getDataList();
      }
      this.selectedRows = [];
      this.total = res.headers.get('X-Total-Count');
    }, () => this.isloadding = false);
  }

  getParams(isReset?: boolean, isSearch?: boolean) {
    const copyParams = {};
    const q = this.queryParams;
    if (isReset === true) {
      this.page = 0;
      this.selectedRows = [];
      Object.keys(q).forEach(function (key) {
        if (key.indexOf('isOk') > -1) {
          q[key] = '';
        } else {
          q[key] = null;
        }
      });
    }
    Object.keys(q).forEach(function (key) {
      if (q[key] !== '' && q[key] !== null) {
        copyParams[key] = q[key];
      }
    });
    if (!isNullOrUndefined(this.contentType)) {
      copyParams['contenttype.equals'] = this.contentType;
    }
    if (!isNullOrUndefined(this.currentBimInfo)) {
      copyParams['bimInfoId.in'] = this.currentBimInfo.id;
    }
    copyParams['page'] = this.page;
    copyParams['size'] = this.size;
    if (isSearch) {
      this.page = 0;
      copyParams['page'] = 0;
    }
    return copyParams;
  }

  /**
   * 获取比赛风采类型
   */
  getCompMienTypes(){
    this.listOfOptions = [];
    
    this.dictService.findAllByDictNo('CompetitionStyleTypes').subscribe(res => {
        let result = res.body;
        this.listOfOptions = result;
    })
  }

  clickContest(bimInfo){
    this.currentBimInfo = bimInfo;


    //权限
    if(this.currentBimInfo && (this.currentBimInfo.type == 'province' || this.currentBimInfo.type == 'enterprise')){
      //省赛和行业赛
      this.isCanSave = this.aclService.canAbility(APP_PERMISSIONS.BIM_COMPETITION_MIEN_EDIT_PROVINCE);
      this.isCanDelete = this.aclService.canAbility(APP_PERMISSIONS.BIM_COMPETITION_MIEN_DELETE_PROVINCE);
    }else{
      this.isCanSave = this.aclService.canAbility(APP_PERMISSIONS.BIM_COMPETITION_MIEN_EDIT_NATIONAL);
      this.isCanDelete = this.aclService.canAbility(APP_PERMISSIONS.BIM_COMPETITION_MIEN_DELETE_NATIONAL);
    }

    this.getDataList();

  }


  /**
  * 页码数量变动事件
  * @param
  */
  paginationChange(event) {
    debugger;
    console.log(event);
    this.page = event.pi - 1;
    this.size = event.ps;
    this.getDataList();
  }

  /**
  * 页码数量变动事件
  * @param
  */
  paginationChangePage(event) {
   
    this.page = event - 1;
    
    this.getDataList();
  }

  pageSizeChangePage(event){
  
    this.size = event;
    this.page = 0;
    
    this.getDataList();
  }

  /**
  * 过滤器变动事件 支持多选过滤
  * @param
  */
  filterChange(event) {
    let i = 0;
    const _value = [];
    const _type = event.filterMultiple ? 'in' : 'equals';
    event.filters.forEach(element => {
      if (element.checked) {
        _value[i++] = element.value;
      }
    });
    this.queryParams[event.indexKey + '.' + _type] = _value;
    // this.getDataList();
  }



  /**
  * 新增页面
  */
  add() {

    if(!this.currentBimInfo){
       this.msg.info('请先选择赛事!');
       return false;
    }
    this.modal
      .static(BimCompetitionMienEditComponent, { record: { id: null,bimInfoId:this.currentBimInfo.id },isAdd: true },1200)
      //      .pipe(filter(w => w === true))
      .subscribe((res) => {
        if(res === 'success'){
          this.getDataList();
        }
      });
  }

  edit(record){
    this.modal
    .static(BimCompetitionMienEditComponent, { record: record,isAdd: false,isCanDelete:this.isCanDelete },1200)

    .subscribe((res) => {
      if(res === 'success'){
        this.getDataList();
      }
      
    });
  }

  view(record){
    this.modal
    .static(BimCompetitionMienEditComponent, { record: record,isAdd: false,isView: true,isCanDelete:this.isCanDelete },1200)

    .subscribe((res) => {

    });
  }

  remove(item) {
    this.modalService.confirm({
      nzTitle: '确认删除?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        this.deleteData(item);
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
  }

  deleteData(item) {
    this.isloadding = true;
    this.thsNewsService.deleteBimCompetitionMien(item.id).subscribe(res => {
      this.isloadding = false;
      this.msg.remove();
      this.msg.success('删除成功！');
      this.page = 0;
      this.getDataList();
    }, () => this.isloadding = false);
    
  }
  
  deleteBatchData() {
    if(!this.selectedRows || this.selectedRows.length <= 0 ){
      this.msg.warning('请先选择要删除的数据！');
      return;
    }
    const ids = this.selectedRows.map(w=>w.id);
    this.isloadding = true;
    this.thsNewsService.deleteBimCompetitionMien(ids).subscribe(res => {
      this.isloadding = false;
      this.msg.remove();
      this.msg.success('删除成功！');
      this.page = 0;
      this.getDataList();
    }, () => this.isloadding = false);
  }


  /**
  * 复选框事件
  * @param {SimpleTableData[]} list
  */
  checkboxChange(list: SimpleTableData[]) {
    this.selectedRows = list;
    this.totalCallNo = this.selectedRows.reduce(
      (total, cv) => total + cv.callNo,
      0,
    );
  }

  refreshStatus(): void {
    const allChecked = this.list.every(value => value.checked === true);
    const allUnChecked = this.list.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);

    
    this.selectedRows = this.list.filter(w=>w.checked === true);
    console.log('refreshStatus: ',this.selectedRows);
  }

  checkAll(value: boolean): void {
    this.list.forEach(data => data.checked = value);
    this.selectedRows = [...this.list];
    console.log('checkAll: ',this.selectedRows);
    this.refreshStatus();
  }
}
