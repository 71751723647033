import { Component, OnInit,Inject,Output,EventEmitter,ViewChildren,QueryList } from '@angular/core';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalHelper } from '@delon/theme';
import { BimInfoService } from 'app/routes/business/bim/bim-info/bim-info.service';
import { NzMessageService,NzModalService, NzModalRef } from 'ng-zorro-antd';
import { isNullOrUndefined } from 'util';
import { ThsNewsService } from '../../ths-news/ths-news.service';
import { DictService } from '@shared/components/dict/dict.service';
import { HttpAddressService } from '@shared/session/http-address.service';
import { ITokenService, DA_SERVICE_TOKEN } from '@delon/auth';
import { GuidUtil } from '@shared/utils/guid.util';
import * as moment from 'moment';
import { FileUploadComponent } from '@shared/components/fileUpload/fileUpload.component';
// import { isTemplateRef } from 'ng-zorro-antd/src/core/util/check';

@Component({
  selector: 'app-bim-competition-mien-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.less']
})
export class BimCompetitionMienEditComponent implements OnInit {

  isAdd = false;
  isView = false;//是否只能查看
  isCanDelete = true;//是否允许删除
  record: any = {};
  contentType = 'bimCompetitionMien';//比赛风采
  @ViewChildren('upload') uploads:QueryList<FileUploadComponent>;

  form: FormGroup;
  public loading = false;

  public dataId = null;
  public bimInfoList = [];

  public bimInfoId:string;
  tabs = [];
  listOfOptions = [];
  list = [];
  fileList = [];
  editIndex = 1;
  editData = {};
  // 上传地址
  uploadAddress: string;
  // 下载地址
  downloadAddress = '';
  previewAddress: string;
  entityId = '';
  entityName = '';
  // curTypeId = '';
  curTypeData = null;
  isPreviewImg = false;//是否需要预览图片
  isNeedCoverImg = false;//是否需要封面照片
  uploadImageFileType = ['.bmp','.jpg','.jpeg','.png','.gif','.BMP','.JPG','.JPEG','.PNG','.GIF'];
  uploadVideoFileType = ['.mp4','.MP4','.mov','.MOV'];
  
  /**
  * 
  */
  @Output() onDeleteSuccess: EventEmitter<{}> = new EventEmitter<{}>();


  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private msg: NzMessageService,
    private nzModalRef: NzModalRef,
    private modalHelp: ModalHelper,
    private modalService: NzModalService,
    private bimInfoService: BimInfoService,
    private thsNewsService: ThsNewsService,
    private httpAddressService: HttpAddressService,
    @Inject(DA_SERVICE_TOKEN) tokenService: ITokenService,
    private dictService:DictService,
  ) {
    this.form = this.fb.group({
      id: [null, []],
      bimInfoId: [this.bimInfoId, []],
      title: [null, [Validators.required, Validators.maxLength(50)]],//类型名称
      author: [null, []],//类型id
      edit: [null, []],//类型编码
      istop: [0, []],//是否置顶
      isreport: [1, []],//类型排序
      createTime: [null, []],
      remark: [null, []],
      content: [null, []],
      isDelete: [true, []],
      hit: [0, []],
      contenttype: [null, []],
      typeId:[null, [Validators.required]],
    });

    this.entityId = tokenService.get().entity_id;
    this.entityName = tokenService.get().entity_name;
    this.uploadAddress = '/thsadmin/api/sys-files/upload';
    this.downloadAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/download/';
    this.previewAddress = httpAddressService.apiGateway + httpAddressService.systemServe + '/sys-files/preview/';
  }

  ngOnInit(): void {

    this.bimInfoId = this.record.bimInfoId;

    if (this.record !== null && !isNullOrUndefined(this.record.id)) {
      this.dataId = this.record.id;
      this.loadData();
    }else{
      this.record.id = 'add_' + new GuidUtil().toString();
      this.addRow();
      this.getOrderData();
    }
    
    this.getCompMienTypes();

  }

  addRow(){
    const sort = (this.list.length + 1);
    const item = {
      id: new GuidUtil().toString(),
      title: this.curTypeData && this.curTypeData.itemText,
      content: null,
      hit: sort,
      isreport: this.form.get('isreport').value,
      isDelete: this.form.get('isDelete').value,
      edit: this.curTypeData && this.curTypeData.itemValue1,
      remark: null,
      extField1: 'add',
    }
    this.list = [ ...this.list,item ];
   
    this.addFormControl(item);
  }

  addFormControl(item){
    // this.form.addControl('typeId_'+ item.id + '_title', new FormControl(item.title, Validators.required));
    this.form.addControl('typeId_'+ item.id + '_content' , new FormControl(item.content, Validators.required));
    this.form.addControl('typeId_'+ item.id + '_hit', new FormControl(item.hit, []));
    this.form.addControl('typeId_'+ item.id + '_remark', new FormControl(item.remark, []));
  }

  removeRow(item) {
    this.list = this.list.filter(item2 => {
      return item2.id !== item.id
    })
   
    this.form.removeControl('typeId_'+ item.id + '_content');
    this.form.removeControl('typeId_'+ item.id + '_hit');
    this.form.removeControl('typeId_'+ item.id + '_remark');
    
  }

  /**
   * 获取比赛风采类型
   */
  getCompMienTypes(){
    this.listOfOptions = [];
    
    this.dictService.findAllByDictNo('CompetitionStyleTypes').subscribe(res => {
        let result = res.body;
        this.listOfOptions = result;
        console.log('typeId',this.record.author);
        if(this.record.author){
          const selOptions = result.filter(w=>w.id === this.record.author);
          console.log('selOptions',selOptions);
          if(selOptions && selOptions.length > 0){
            this.curTypeData = selOptions[0];
            if(this.curTypeData.itemValue1 === 'Photo'){
              this.isPreviewImg = true;
            }else if(this.curTypeData.itemValue1 === 'Video'){
              this.isNeedCoverImg = true;
            }
          }
        }
    })
  }

  changeCompType(id){
    const options = this.listOfOptions.filter(w=>w.id === id);
    const data = options[0];
    this.form.get('title').setValue(data.itemText);
    this.form.get('author').setValue(data.id);
    this.form.get('edit').setValue(data.itemValue1);
    this.curTypeData = data;
   
    if(data.itemValue1 === 'Photo'){
      this.isPreviewImg = true;
    }else if(data.itemValue1 === 'Video'){
      this.isNeedCoverImg = true;
    }
  }

  

  loadData() {
    this.form.patchValue(this.record);
   
    if(this.record.isDelete === '0'){
      this.form.get('isDelete').setValue(true);
    }else{
      this.form.get('isDelete').setValue(false);
    }

    this.form.get('typeId').setValue(this.record.author);

    this.getDatas();
  }

  //获取排序数据（新增时）
  getOrderData(){
    if(!this.isAdd){
      return;
    }
    const copyParams = {
      'bimInfoId.equals': this.bimInfoId,
      'contenttype.equals': this.contentType,
      'page': 0,
      'size': 2000,
      sort: ['isreport,desc']
    }
    
    this.thsNewsService.query(copyParams).subscribe(res => {
      let result = res.body;
      
      if(result && result.length > 0){
        let order = !result[0].isreport ? parseInt(result[0].isreport) : 1;
        if(isNaN(order)){
          order = 0;
        }
        this.form.get('isreport').setValue(order+1);
      }
      
    });
  }

  getDatas(){
    if(this.isAdd){
      return;
    }
    const copyParams = {
      'bimInfoId.equals': this.bimInfoId,
      'contenttype.equals': this.contentType,
      'author.equals': this.record.author,
      'page': 0,
      'size': 2000,
      sort: ['hit,asc']
    }
    
    this.thsNewsService.query(copyParams).subscribe(res => {
      let result = res.body;
      if(!result){
        result = [];
      }
      if(res.body){
        result.forEach(item => {
          this.addFormControl(item);
        })
      }
      if(result.length <= 0){
        this.addRow();
      }else{
        this.list = result;
      }
      
    });
  }

  uploadFinishImg(data){
    console.log(data);
    if(data.success){
      this.fileList.push(data.file);
    }
  }
  uploadFinishVideo(data){
    console.log(data);
    if(data.success){
      this.fileList.push(data.file);
    }
  }

  // 提交
  submit() {
    if(this.isView){
      this.onError({ 'message': '未设置编辑权限，无法保存' });
      return;
    }
    const subParam = {};
    for (const key in this.form.controls) {
      this.form.controls[ key ].markAsDirty();
      this.form.controls[ key ].updateValueAndValidity();
      subParam[key] =  this.form.controls[key].value;
    }
    
    if (this.form.invalid) {
   
      console.warn(this.form.errors);
      this.onError({ 'message': '请检查信息是否完整！' });
      return;
    }
    if (!isNullOrUndefined(this.bimInfoId)) {
      subParam['bimInfoId']  = this.bimInfoId;
    }else{
      this.onError({ 'message': '请先选择赛事' });
      return;
    }
    
    console.log(subParam);
    
    this.saveOrUpdate(subParam);
  }


  deleteData(tabIndex,item){
    this.modalService.confirm({
      nzTitle: '确认删除吗?',
      nzContent: '',
      nzOkText: '是',
      nzOkType: 'danger',
      nzOnOk: () => {
        delete this.editData[ item.key ];
      },
      nzCancelText: '否',
      nzOnCancel: () => console.log('Cancel')
    });
    
  }

  saveBefore(subParam: any) {
    
    subParam['isDelete'] = subParam['isDelete'] === true ? 0 : 1;
    subParam['title'] = subParam['title'].trim();
    if (!isNullOrUndefined(this.contentType)) {
      subParam['contenttype'] = this.contentType;
    }
    const content = subParam['content'];
    if (content && content.length > 0) {
      subParam['content'] = content.replace(new RegExp('align="center"', 'g'), 'style="margin: auto;"');
    }

    // this.form.addControl('typeId_'+ item.id + '_content', new FormControl(null, Validators.required));
    // this.form.addControl('typeId_'+ item.id + '_hit', new FormControl(item.hit, []));
    // this.form.addControl('typeId_'+ item.id + '_remark', new FormControl(null, []));
   
    if(this.list && this.list.length > 0){
      let keys = Object.keys(this.form.controls);

      this.list.forEach(item => {
        item['contenttype'] = this.contentType;
        item['title'] = this.form.get('title').value;//风采类型（照片、视频）
        item['isreport'] = this.form.get('isreport').value;
        item['isDelete'] = this.form.get('isDelete').value;
        const author = this.form.get('author').value;
        item['author'] = author ? author: this.curTypeData.id;//风采类型id
        const edit = this.form.get('edit').value;
        item['edit'] = edit ? edit: this.curTypeData.itemValue1;//风采类型编码
        item['bimInfoId'] = this.bimInfoId;
        if(item['createTime']){
          item['createTime'] = moment(item['createTime']);
        }
        if(item['updateTime']){
          item['updateTime'] = moment(item['updateTime']);
        }
        
        item.bimInfoId = this.bimInfoId;
        const contentKey = 'typeId_'+item.id +'_content' ;
        let control = this.form.get(contentKey);
        item['content'] = control ? control.value : null;

        const remarkKey = 'typeId_'+item.id +'_remark';
        control = this.form.get(remarkKey);
        item['remark'] = control ? control.value : null;

        const hitKey = 'typeId_'+item.id +'_hit';
        control = this.form.get(hitKey);
        item['hit'] = control ? control.value : null;
      })
    }

    
    const fileList2 = [];
    if(this.uploads != null){
      this.uploads.forEach(up => {
        if(up.dataSet && up.dataSet.length > 0){
          up.dataSet.forEach(item => {
            const copyItem = Object.assign({},item);
            if(copyItem.createdDate){
              copyItem.createdDate = moment(copyItem.createdDate);
            }
            if(copyItem.lastModifiedDate){
              copyItem.lastModifiedDate = moment(copyItem.lastModifiedDate);
            }
            fileList2.push(copyItem);
          })
        }
      });
    }
    // if(this.fileList && this.fileList.length > 0){
    //   this.fileList.forEach(item => {
    //     const copyItem = Object.assign({},item);
    //     if(copyItem.createdDate){
    //       copyItem.createdDate = moment(copyItem.createdDate);
    //     }
    //     if(copyItem.lastModifiedDate){
    //       copyItem.lastModifiedDate = moment(copyItem.lastModifiedDate);
    //     }
    //     fileList2.push(copyItem);
    //   })
    // }

    if(subParam.createTime){
      subParam.createTime = moment(subParam.createTime);
    }
    
    const data = {};

    data['thsNews'] = Object.assign({},subParam);
    data['thsNewsList'] = this.list;
    data['fileList'] = fileList2;

    return data;
  }

  saveOrUpdate(subParam: any) {

    this.loading = true;

    const datas = this.saveBefore(subParam);
    
    this.thsNewsService.saveOrUpdateBimCompetitionMien(datas).subscribe(res => {
      this.loading = false;
      if(res.body && res.body.id){
        this.msg.info('保存成功');
        this.modal.close('success');
        this.record.id = res.body.id;
      }else{
        this.msg.info('保存失败');
        this.record.id = res.body.id;
      }
    }, () => this.loading = false);
  }
  


  close() {
    this.nzModalRef.destroy(this.dataId);
  }

  private onError(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('error', error.message);
  }

  private onWarning(error: { message: string; }) {
    this.msg.remove();
    this.msg.create('warning', error.message);
  }
}
